import { useLocation } from 'react-router-dom';

const getPathLength = (path: string): number => {
  return path.split('/').length;
};

const DEFAULT_PATH = '/';

// Inspired by: https://mui.com/material-ui/guides/routing/#tabs
export const useRouteMatch = (paths: string[]): string => {
  const { pathname } = useLocation();
  const sortedPaths = paths.sort((a, b) => getPathLength(b) - getPathLength(a));

  for (let i = 0; i < sortedPaths.length; i += 1) {
    const pattern = sortedPaths[i];
    if (pathname.startsWith(pattern)) {
      return pattern;
    }
  }

  return DEFAULT_PATH;
};
