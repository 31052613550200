import { Drawer } from '@mui/material';
import styled from '@emotion/styled';
import { Colors } from '../../../Foundation/Colors';
import { Fonts } from '../../../Foundation/Fonts';

const sideNavWidth = 218;
const closedSideNavWidth = 14;

export const StyledCollapser = styled.button<{
  open: boolean;
}>`
  padding: 0;
  z-index: 1;
  display: flex;
  position: absolute;
  top: 150px;
  left: ${({ open }) =>
    open ? `${sideNavWidth}px` : `${closedSideNavWidth}px`};
  transition: left 200ms;

  border-radius: 0px 8px 8px 0px;
  border: 1px solid ${Colors.grey_blue_1};
  border-left: none;
  background-color: ${Colors.grey_blue_0};

  svg {
    margin-left: -6px;
    transform: ${({ open }) => (open ? 'rotate(270deg)' : 'rotate(90deg)')};
    transition: transform 200ms;

    fill: ${Colors.grey_blue_3};
    cursor: pointer;
  }
`;

export const StyledDrawer = styled(Drawer)`
  width: ${({ open }) => (open ? sideNavWidth : closedSideNavWidth)}px;
  overflow: hidden;
  transition: width 200ms;

  .MuiDrawer-paper {
    position: relative;
    overflow: hidden;
    left: ${({ open }) =>
      open ? 0 : `${sideNavWidth * -1 + closedSideNavWidth}px`};
    transition: left 200ms;
    width: ${sideNavWidth}px;
    background-color: ${Colors.grey_blue_0};
    border-right: 0.88px solid ${Colors.grey_blue_1};
    box-sizing: border-box;
  }

  .MuiDivider-root {
    margin: 0 18px;
    background-color: ${Colors.grey_blue_1};
  }

  .navigation-page-info {
    padding: 20px 18px 22px 19px;
    align-items: center;

    .icon-wrapper {
      height: 32px;
      width: 32px;
      margin-right: 7.5px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      box-sizing: border-box;

      border-radius: 4px;
      background-color: ${Colors.accent_3};

      svg {
        fill: ${Colors.accent};
      }
    }

    .MuiTypography-root {
      ${Fonts.subheadSmall}
    }
  }

  .MuiListItem-root:hover,
  .MuiListItem-root.Mui-selected:hover,
  .side-nav-nested-items .MuiListItem-button:hover {
    background-color: rgba(207, 207, 255, 0.2);
  }

  .side-nav-items {
    ${Fonts.body};
    line-height: 24px;
    color: ${Colors.grey_blue_5};

    &.MuiListItem-gutters {
      padding: 12px 26px 12px 18px;
    }
  }

  .side-nav-items.Mui-selected,
  .side-nav-nested-items .Mui-selected {
    ${Fonts.link};
    background-color: ${Colors.grey_blue_0};
    color: ${Colors.accent_dark};
  }

  .side-nav-nested-items {
    ${Fonts.caption}
    line-height: 24px;
    color: ${Colors.grey_blue_4};
    padding: 0;

    .MuiListItem-root {
      padding: 6px 0 6px 54px;
    }

    .Mui-selected {
      font-size: 12px;
    }
  }
`;
