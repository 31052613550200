import { useAuth0, User } from '@auth0/auth0-react';
import { useEffect, useState, useCallback } from 'react';

type UserClaimsType = User & {
  [key: string]: string | boolean | null;
};

type NormalisedUserClaims = UserClaimsType & {
  account_id?: string | null;
  company_name?: string | null;
  user_id?: string | null;
  requiresIdentityMigration?: boolean | null;
};

export const useUser = () => {
  const { user, isLoading, error, logout, getIdTokenClaims } = useAuth0();
  const [userClaims, setUserClaims] = useState<UserClaimsType>({});
  const [normalisedUserClaims, setNormalisedUserClaims] =
    useState<NormalisedUserClaims>({});

  const TOKEN_CLAIM_NAMESPACE = process.env.REACT_APP_TOKEN_CLAIM_NAMESPACE;

  useEffect(() => {
    if (!isLoading) {
      const getClaims = async () => {
        const claims = { ...(await getIdTokenClaims()) };

        const normalisedUserClaims = Object.entries(claims).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key.replace(`${TOKEN_CLAIM_NAMESPACE}/`, '')]: value,
          }),
          {} as NormalisedUserClaims,
        );

        setUserClaims(claims);
        setNormalisedUserClaims(normalisedUserClaims);
      };
      getClaims();
    }
  }, [getIdTokenClaims, isLoading]);

  const logoutRedirect = useCallback(
    () => logout({ returnTo: window.location.origin }),
    [],
  );

  return {
    user,
    userClaims,
    normalisedUserClaims,
    isLoading,
    error,
    getIdTokenClaims,
    logout: logoutRedirect,
  };
};

export type UseUserResult = ReturnType<typeof useUser>;
