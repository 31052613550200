import { Action, Page } from './components/Navigation';

export const PAGES: Array<Page> = [
  {
    title: 'Contacts',
    pathname: '/contacts',
    icon: 'ContactsOutline',
    links: [
      {
        label: 'Contacts',
        pathname: '/contacts',
      },
      {
        label: 'Lists',
        pathname: '/contacts/lists',
      },
    ],
  },
  {
    title: 'Messages',
    pathname: '/messages',
    icon: 'MessagesOutline',
    links: [
      {
        label: 'Messages',
        pathname: '/messages',
        children: [
          {
            label: 'New Message',
            pathname: '/messages/channels',
          },
          {
            label: 'Drafts',
            pathname: '/messages/draft-messages',
          },
          {
            label: 'Sent',
            pathname: '/messages/sent-messages',
          },
          {
            label: 'Archived',
            pathname: '/messages/archived-messages',
          },
        ],
      },
      {
        label: 'WhatsApp Templates',
        pathname: '/messages/whatsapp/templates',
      },
      {
        label: 'Classic Templates',
        pathname: '/messages/classic',
      },
    ],
  },
  {
    title: 'Workflows',
    pathname: '/workflows',
    icon: 'WorkflowOutline',
    links: [
      {
        label: 'Workflows',
        pathname: '/workflows',
      },
      {
        label: 'Templates',
        pathname: '/workflows/templates',
      },
    ],
  },
  {
    title: 'Analytics',
    pathname: '/analytics',
    icon: 'AnalyticsOutline',
    links: [
      {
        label: 'Activity',
        pathname: '/analytics/activity',
      },
      {
        label: 'Engagement',
        pathname: '/analytics/engagement',
      },
      {
        label: 'Recipient',
        pathname: '/analytics/messages/recipients',
      },
      {
        label: 'Forms Insight',
        pathname: '/analytics/forms',
      },
    ],
  },
];

export const ACTIONS: Array<Action> = [
  {
    title: 'Account',
    icon: 'AccountCircle',
    links: [
      {
        title: 'Your Account',
        pathname: '/settings/account',
        icon: 'AccountCircle',
      },
      {
        title: 'API Keys',
        pathname: '/settings/apikeys',
        icon: 'ApiKeys',
      },
      {
        title: 'Brand Settings',
        pathname: '/settings/brand',
        icon: 'Cog',
      },
      {
        title: 'Logout',
        pathname: '/logout',
        icon: 'Exit',
      },
    ],
  },
];
