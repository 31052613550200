import { Box } from '@mui/material';
import React from 'react';
import { StyledMenuNavigation } from './Navigation.styles';
import { MenuNavigationProps } from './Navigation.types';
import { SecondaryNavigation } from './SecondaryNavigation';
import { PrimaryNavigation } from './PrimaryNavigation';
import { useRouteMatch } from './hooks';

export const Navigation = ({
  children,
  pages,
  actions,
}: MenuNavigationProps) => {
  const activePath = useRouteMatch(pages.map(({ pathname }) => pathname));
  const activePage = pages.find(({ pathname }) => pathname === activePath);

  return (
    <StyledMenuNavigation maxWidth={false}>
      <PrimaryNavigation pages={pages} actions={actions} />
      <Box display="flex" position="relative">
        {activePage && <SecondaryNavigation activePage={activePage} />}
        <main aria-label="application" className="navigation-content">
          {children}
        </main>
      </Box>
    </StyledMenuNavigation>
  );
};
