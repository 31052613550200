import { useEffect } from 'react';
import { useUser } from '../AuthProvider';
import { LoadingScreen } from '../LoadingScreen';

export const Logout = () => {
  const { logout } = useUser();

  useEffect(() => {
    logout();
  }, []);

  return <LoadingScreen />;
};
