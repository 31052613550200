import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { getAuth0Config } from './configs';
import { AuthProviderType } from './AuthProvider.types';
import { AuthenticatedUser } from './AuthenticatedUser';
import { MigratedUser } from './MigratedUser';

export const AuthProvider = ({ children }: AuthProviderType) => (
  <Auth0Provider {...getAuth0Config()}>
    <AuthenticatedUser>
      <MigratedUser>{children}</MigratedUser>
    </AuthenticatedUser>
  </Auth0Provider>
);
