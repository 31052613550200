import { ReactComponent as AccountCircle } from '../Assets/Svgs/AccountCircle.svg';
import { ReactComponent as AnalyticsOutline } from '../Assets/Svgs/AnalyticsOutline.svg';
import { ReactComponent as ApiKeys } from '../Assets/Svgs/ApiKeys.svg';
import { ReactComponent as Cog } from '../Assets/Svgs/Cog.svg';
import { ReactComponent as ContactsOutline } from '../Assets/Svgs/ContactsOutline.svg';
import { ReactComponent as ConversationsOutline } from '../Assets/Svgs/ConversationsOutline.svg';
import { ReactComponent as DropupArrow } from '../Assets/Svgs/DropupArrow.svg';
import { ReactComponent as Exit } from '../Assets/Svgs/Exit.svg';
import { ReactComponent as Futuro } from '../Assets/Svgs/Futuro.svg';
import { ReactComponent as Logo } from '../Assets/Svgs/Logo.svg';
import { ReactComponent as MessagesOutline } from '../Assets/Svgs/MessagesOutline.svg';
import { ReactComponent as WorkflowOutline } from '../Assets/Svgs/WorkflowOutline.svg';

export const Icons = {
  AccountCircle,
  AnalyticsOutline,
  ApiKeys,
  Cog,
  ContactsOutline,
  ConversationsOutline,
  DropupArrow,
  Exit,
  Futuro,
  Logo,
  MessagesOutline,
  WorkflowOutline,
};

export type IconTypes = keyof typeof Icons;
