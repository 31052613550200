import React from 'react';
import { useAuth, useUser } from '../hooks';
import { AuthProviderType } from '../AuthProvider.types';
import { LoadingScreen } from '../../LoadingScreen';
import { useLocation } from 'react-router';

export const AuthenticatedUser = ({ children }: AuthProviderType) => {
  const auth = useAuth();
  const user = useUser();

  React.useEffect(() => {
    window.wspAuth = auth;
    window.wspUser = user;
  }, [auth, user]);

  const { normalisedUserClaims } = user;
  const { account_id, company_name, user_id, name, email } =
    normalisedUserClaims;

  // Group & Identity Tracking
  React.useEffect(() => {
    if (account_id && user_id) {
      analytics.group(account_id, {
        userId: user_id,
        name: company_name,
      });
      analytics.identify(user_id, {
        name,
        email,
      });
    }
  }, [user.normalisedUserClaims]);

  // Page Tracking
  const { pathname } = useLocation();
  React.useEffect(() => {
    if (account_id && user_id) {
      analytics.page(pathname, {
        userId: user_id,
      });
    }
  }, [user.normalisedUserClaims, pathname]);

  const { isAuthenticated, isLoading, error } = auth;

  if (error) {
    return <p>Oops! Something went wrong!</p>;
  }

  if (isLoading || !isAuthenticated) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
};
