import { Container } from '@mui/material';
import styled from '@emotion/styled';
import { Colors } from '../../Foundation/Colors';
import { navBarHeight } from './PrimaryNavigation/PrimaryNavigation.styles';

export const StyledMenuNavigation = styled(Container)`
  font-family: 'Inter', 'Capisce-Display', 'Capisce-Text', sans-serif;
  position: relative;

  a,
  a:visited,
  a:hover,
  a:active {
    text-decoration: none;
    color: inherit;
  }

  &.MuiContainer-root {
    padding: 0;
  }

  .navigation-content {
    display: flex;
    flex-grow: 1;
    height: calc(100vh - ${navBarHeight}px);
    overflow-y: auto;
    position: relative;

    > * {
      width: 100%;
      height: 100%;
      background-color: ${Colors.background};
    }
  }
`;
