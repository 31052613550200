import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const useAuth = () => {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    error,
    getAccessTokenSilently,
  } = useAuth0();

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }
    const redirect = async () =>
      await loginWithRedirect({
        redirectUri: window.location.origin,
        appState: { returnTo: window.location.href },
      });
    redirect();
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  return {
    isLoading: isLoading || !isAuthenticated,
    isAuthenticated,
    error,
    getAccessToken: getAccessTokenSilently,
  };
};

export type UseAuthResult = ReturnType<typeof useAuth>;
