import React from 'react';
import { AuthProviderType } from '../AuthProvider.types';
import { useUser } from '../hooks';
import { navigateTo } from '../utils';
import {
  CLASSIC_MIGRATION,
  MIGRATION_PAGE,
  DASHBOARD_PAGE,
} from './MigratedUser.constants';
import { LoadingScreen } from '../../LoadingScreen';

export const MigratedUser = ({ children }: AuthProviderType) => {
  const { normalisedUserClaims } = useUser();

  const isMigrationPage = window.location.pathname === MIGRATION_PAGE;
  const hasMigrationClaim =
    normalisedUserClaims.hasOwnProperty(CLASSIC_MIGRATION);
  const isMigrating =
    hasMigrationClaim && normalisedUserClaims[CLASSIC_MIGRATION];

  if (!hasMigrationClaim && isMigrationPage) {
    return <LoadingScreen />;
  }

  if (isMigrating && !isMigrationPage) {
    navigateTo(MIGRATION_PAGE);
    return <LoadingScreen />;
  }

  if (!isMigrating && isMigrationPage) {
    navigateTo(DASHBOARD_PAGE);
    return <LoadingScreen />;
  }

  return <>{children}</>;
};
