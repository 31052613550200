import React from 'react';
import {
  AppBar,
  Box,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
  IconButton,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Icons } from '../../../Foundation/Icons';
import { PrimaryNavigationProps } from './PrimaryNavigation.types';
import { StyledTopNavBarContainer } from './PrimaryNavigation.styles';
import { useRouteMatch } from '../hooks';

export const PrimaryNavigation = ({
  pages,
  actions,
}: PrimaryNavigationProps) => {
  const isOnboarding = window.location.pathname === '/onboarding';

  const currentTab = useRouteMatch(pages.map(({ pathname }) => pathname));

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <StyledTopNavBarContainer>
      <AppBar position="relative" elevation={0}>
        <Toolbar>
          <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              component="nav"
              aria-label="primary"
            >
              <Tabs className="topnavbar-tabs" value={currentTab}>
                <Tab
                  key="/"
                  aria-label="whispir logo"
                  to="/"
                  value="/"
                  component={Link}
                  label={
                    <div className="logo-container">
                      <Box mr={4} className="futuro-logo">
                        <Icons.Futuro />
                      </Box>
                      <Box mr={4} className="whispir-logo">
                        <Icons.Logo />
                      </Box>
                    </div>
                  }
                  disabled={isOnboarding}
                />
                {!isOnboarding &&
                  pages.map(({ title, pathname }) => (
                    <Tab
                      key={title}
                      label={title}
                      aria-label={title}
                      value={pathname}
                      to={pathname}
                      component={Link}
                    />
                  ))}
              </Tabs>
            </Box>
            <Box
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {actions.map(({ title, icon, links }) => {
                const ActionIcon = Icons[icon];
                return (
                  <React.Fragment key={title}>
                    <Tooltip
                      PopperProps={{ disablePortal: true, 'aria-label': title }}
                      title={title}
                      aria-label={title}
                      arrow
                    >
                      <IconButton onClick={handleClick}>
                        <ActionIcon />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      disablePortal={true}
                    >
                      {links.map(({ title, pathname, icon }) => {
                        const LinkIcon = Icons[icon];
                        return (
                          <Link key={pathname} to={pathname}>
                            <MenuItem onClick={handleClose} aria-label={title}>
                              <ListItemIcon>
                                <LinkIcon className="link-item" />
                              </ListItemIcon>
                              <ListItemText>{title}</ListItemText>
                            </MenuItem>
                          </Link>
                        );
                      })}
                    </Menu>
                  </React.Fragment>
                );
              })}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </StyledTopNavBarContainer>
  );
};
