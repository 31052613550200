import { Box, Divider, List, ListItem, Typography } from '@mui/material';
import React, { useState } from 'react';
import { StyledCollapser, StyledDrawer } from './SecondaryNavigation.styles';
import { Link } from 'react-router-dom';
import { SecondaryNavigationProps } from './SecondaryNavigation.types';
import { Icons } from '../../../Foundation/Icons';
import { useRouteMatch } from '../hooks';

export const SecondaryNavigation = ({
  activePage,
}: SecondaryNavigationProps) => {
  const [open, setOpen] = useState(true);

  const pageLinks = activePage.links.map(({ pathname }) => pathname);
  const childLinks = activePage.links
    .map(({ children }) =>
      children ? children.map(({ pathname }) => pathname) : [],
    )
    .flat();
  const allLinks = [...pageLinks, ...childLinks];
  const activeLink = useRouteMatch(allLinks);

  const handleToggleSideNav = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  const ActiveIcon = Icons[activePage.icon];

  return (
    <>
      <StyledCollapser
        open={open}
        aria-label="secondary navigation toggle"
        onClick={() => handleToggleSideNav(!open)}
      >
        <Icons.DropupArrow />
      </StyledCollapser>
      <StyledDrawer
        transitionDuration={200}
        anchor="left"
        open={open}
        variant="permanent"
        aria-hidden={!open}
      >
        <Box display="flex" className="navigation-page-info">
          <Box className="icon-wrapper">
            <ActiveIcon />
          </Box>
          <Typography>{activePage.title}</Typography>
        </Box>
        <Divider />
        <List component="nav" aria-label="secondary">
          {activePage.links.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <Link to={item.pathname}>
                  <ListItem
                    button
                    aria-label={item.label}
                    className="side-nav-items"
                    selected={item.pathname === activeLink}
                  >
                    <Box display="flex" flexGrow={1}>
                      <Box flexGrow={1}>{item.label}</Box>
                      {item.count}
                    </Box>
                  </ListItem>
                </Link>
                {!!item.children && (
                  <List component="div" className="side-nav-nested-items">
                    {item.children.map((child, childIndex) => (
                      <Link to={child.pathname} key={childIndex}>
                        <ListItem
                          button
                          aria-label={child.label}
                          selected={child.pathname === activeLink}
                        >
                          {child.label}
                        </ListItem>
                      </Link>
                    ))}
                  </List>
                )}
              </React.Fragment>
            );
          })}
        </List>
      </StyledDrawer>
    </>
  );
};
