import { Box } from '@mui/material';
import { Icons } from '../../Foundation/Icons';
import { StyledLoadingScreenContainer } from './LoadingScreen.styles';

export const LoadingScreen = () => {
  return (
    <StyledLoadingScreenContainer role="status" aria-label="loading">
      <Box mr={4} className="futuro-logo">
        <Icons.Futuro />
      </Box>
      <Box mr={4} className="whispir-logo">
        <Icons.Logo />
      </Box>
    </StyledLoadingScreenContainer>
  );
};
