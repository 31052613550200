export const getAuth0Config = () => {
  const {
    REACT_APP_AUTH0_DOMAIN,
    REACT_APP_AUTH0_CLIENT_ID,
    REACT_APP_AUTH0_AUDIENCE,
  } = process.env;

  return {
    domain: REACT_APP_AUTH0_DOMAIN as string,
    audience: REACT_APP_AUTH0_AUDIENCE as string,
    clientId: REACT_APP_AUTH0_CLIENT_ID as string,
  };
};
