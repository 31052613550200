import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { AuthProvider } from './components/AuthProvider';
import { Navigation } from './components/Navigation';
import { CustomHistoryRouter } from './components/CustomHistoryRouter';
import { Logout } from './components/Logout';
import { AppProps, Hosts } from './App.types';
import { ACTIONS, PAGES } from './App.nav';

window.wspHistory = createBrowserHistory();

export const App = ({ hosts = {}, children = {} }: AppProps) => {
  const defaultHosts: Hosts = {
    'wsp-microfrontend-workflows': process.env
      .REACT_APP_WORKFLOWS_HOST as string,
    'wsp-microfrontend-contacts': process.env.REACT_APP_CONTACTS_HOST as string,
    'wsp-microfrontend-accounts': process.env.REACT_APP_ACCOUNTS_HOST as string,
    'wsp-microfrontend-analytics': process.env
      .REACT_APP_ANALYTICS_HOST as string,
    'wsp-microfrontend-message-studio': process.env
      .REACT_APP_MESSAGE_STUDIO_HOST as string,
  };

  const mergedHosts = { ...defaultHosts, ...hosts };
  const hasChildren = Object.keys(children).length !== 0;
  const appHosts = hasChildren ? children : mergedHosts;

  React.useEffect(() => {
    Object.entries(appHosts).forEach(([customElementName, host]) => {
      const scriptId = `microfrontend-script-${customElementName}`;

      if (document.getElementById(scriptId)) {
        // Don't append the script if it already exists in the document head
        return;
      }

      const script = document.createElement('script');
      script.id = scriptId;
      script.src = host;
      document.head.appendChild(script);
    });
  }, [appHosts]);

  const injectedChildren = hasChildren
    ? Object.keys(appHosts).map((customElementName) =>
        React.createElement(customElementName, { key: customElementName }),
      )
    : [];

  const [hideNav, setHideNav] = useState(false);
  const [randomDigit, setRandomDigit] = useState('');
  const IUX_MAIN_PAGE =
    process.env.REACT_APP_IUX_HOST +
    '/tmpl/home.tmpl?embedded=true' +
    (hideNav ? '' : '&hideNav=true') +
    '#!';

  const ROUTE_MAPPINGS = {
    '/iux': '/web_com/View_Workspace',
    '/iux/compose': '/web_com/NewMessage',
    '/iux/contacts':
      '/web_tem/Contact_List.fm?&mode=list&action=View&entity=Contact',
  };

  const IUX_MAPPINGS = new Map(
    Object.entries(ROUTE_MAPPINGS).map(([k, v]) => [v, k]),
  );

  const onWatchEventHandler = (
    ev: MessageEvent<{ type: string; message: string; fragment?: string }>,
  ) => {
    if (
      typeof ev.data !== 'object' ||
      ev.data.type !== 'wsp_router' ||
      !ev.data.message
    )
      return;
    if (ev.data.message === 'token_request' && ev.source != null) {
      const responseFrame = ev.source;
      const responseDomain = ev.origin || '*';
      const tokenResponse = (token: String) => {
        responseFrame.postMessage(
          { type: 'wsp_router', message: 'token_response', token: token },
          { targetOrigin: responseDomain },
        );
      };
      // TODO is it the access token or the id token we want?
      window.wspAuth.getAccessToken().then(tokenResponse);
    } else if (
      ev.data.message === 'iux_navigation' &&
      ev.source != null &&
      ev.data.fragment
    ) {
      console.log('%cIUX changed page to ' + ev.data.fragment, 'color: orange');
      const route = IUX_MAPPINGS.get(
        ev.data.fragment.replace(/[?&]rd=\d+/, ''),
      );
      if (route) {
        let match = ev.data.fragment.match(/[?&]rd=(\d+)/);
        console.log(
          '%cPushing history ' + route + ' - ' + (match ? match[1] : ''),
          'color: orange',
        );
        window.wspHistory.push(route, { randomDigit: match ? match[1] : '' });
        setRandomDigit(match ? match[1] : '');
      }
    }
  };

  React.useEffect(() => {
    window.addEventListener('message', onWatchEventHandler);
    return () => window.removeEventListener('message', onWatchEventHandler);
  }, [onWatchEventHandler]);

  const generateIuxFrame = (entry: [string, string]) => {
    const [containerPath, iuxPath] = entry;
    let fullIuxPath = IUX_MAIN_PAGE + iuxPath;
    if (randomDigit) {
      const alreadyHasQueryString = iuxPath.match(/\?/);
      fullIuxPath += (alreadyHasQueryString ? '&' : '?') + 'rd=' + randomDigit;
    }
    return (
      <Route
        key={containerPath}
        path={containerPath}
        element={<iframe id="iux" src={fullIuxPath}></iframe>}
      />
    );
  };

  return (
    <CustomHistoryRouter history={window.wspHistory}>
      <AuthProvider>
        {hasChildren ? (
          <React.Fragment>{injectedChildren}</React.Fragment>
        ) : (
          <Routes>
            <Route path="/logout" element={<Logout />} />
            <Route
              path="/*"
              element={
                <Navigation pages={PAGES} actions={ACTIONS}>
                  <Routes>
                    {/* https://reactrouter.com/docs/en/v6/getting-started/overview#descendant-routes */}
                    <Route
                      path="/"
                      element={
                        <wsp-microfrontend-accounts></wsp-microfrontend-accounts>
                      }
                    />
                    {/* TODO: Should onboarding live under `/settings/onboarding` ?
                This would provide better encapsulation for the "Settings"/Company/Identity domain */}
                    <Route
                      path="/onboarding"
                      element={
                        <wsp-microfrontend-accounts></wsp-microfrontend-accounts>
                      }
                    />
                    <Route
                      path="/migrate-identity"
                      element={
                        <wsp-microfrontend-accounts></wsp-microfrontend-accounts>
                      }
                    />
                    <Route
                      path="/settings/*"
                      element={<div>The settings micro-frontend.</div>}
                    />
                    {Object.entries(ROUTE_MAPPINGS).map(generateIuxFrame)}
                    <Route
                      path="/contacts/*"
                      element={
                        <wsp-microfrontend-contacts></wsp-microfrontend-contacts>
                      }
                    />
                    <Route
                      path="/messages/*"
                      element={
                        <wsp-microfrontend-message-studio></wsp-microfrontend-message-studio>
                      }
                    />
                    <Route
                      path="/analytics/*"
                      element={
                        <wsp-microfrontend-analytics></wsp-microfrontend-analytics>
                      }
                    />
                    <Route
                      path="/workflows/*"
                      element={
                        <wsp-microfrontend-workflows></wsp-microfrontend-workflows>
                      }
                    />
                  </Routes>
                </Navigation>
              }
            />
          </Routes>
        )}
      </AuthProvider>
    </CustomHistoryRouter>
  );
};
