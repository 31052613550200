import styled from '@emotion/styled';
import { Colors } from '../../../Foundation/Colors';
import { Fonts } from '../../../Foundation/Fonts';

const smallMediaTabWidth = '96px';
const largeMediaMaxWidth = '1000px';
const largeMediaTabWidth = '128px';
export const navBarHeight = 80;

export const StyledTopNavBarContainer = styled.div`
  height: ${navBarHeight}px;

  .MuiToolbar-root {
    padding-bottom: 2px;
    padding-left: 0px;
  }

  .MuiAppBar-colorPrimary {
    background-color: ${Colors.background_dark_01};
  }

  .logo-container {
    margin-top: 4px;
    width: 186px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    fill: ${Colors.white};
  }

  .futuro-logo {
    margin-right: 16px;
  }

  .whispir-logo {
    margin-right: 48px;

    svg {
      height: ${navBarHeight}px;
      width: auto;
    }
  }

  .MuiTab-root {
    text-transform: capitalize;
  }

  .MuiTab-root,
  .MuiTabs-fixed,
  .MuiTabs-flexContainer {
    height: ${navBarHeight}px;
  }

  .MuiButtonBase-root.MuiTab-root {
    ${Fonts.link}
    color: ${Colors.white};

    min-width: ${largeMediaTabWidth};
    @media (max-width: ${largeMediaMaxWidth}) {
      min-width: ${smallMediaTabWidth};
    }
    box-sizing: border-box;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }

    :not(&.Mui-selected) {
      opacity: 0.7;
    }

    &.Mui-selected {
      background: rgba(255, 255, 255, 0.15);
    }
  }

  .MuiTabs-indicator {
    height: 5px;
    border-bottom: 2px solid ${Colors.background_dark_01};
    width: ${largeMediaTabWidth};
    @media (max-width: ${largeMediaMaxWidth}) {
      width: ${smallMediaTabWidth};
    }
    background-color: ${Colors.white};
  }

  svg.link-item {
    width: 20px;
    height: 20px;
    fill: ${Colors.grey_blue_3};
  }

  .MuiTooltip-tooltip {
    ${Fonts.caption}
    background: ${Colors.black};
    border-radius: 4px;
    padding: 16px;
  }

  .MuiTooltip-arrow {
    color: ${Colors.black};
  }
`;
