import { Box } from '@mui/material';
import styled from '@emotion/styled';
import { Colors } from '../../Foundation/Colors';

export const StyledLoadingScreenContainer = styled(Box)`
  background-color: ${Colors.background_dark_01};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;

  svg {
    fill: ${Colors.white};
  }

  .futuro-logo {
    margin-right: 32px;

    svg {
      height: 60px;
      width: auto;

      @keyframes rotating {
        100% {
          transform: rotate(360deg);
        }
      }
      animation: rotating 1s infinite;
    }
  }

  .whispir-logo {
    svg {
      height: 160px;
      width: auto;
    }
  }
`;
