import { css } from '@emotion/react';

export const WHISPIR_FONTS_ID = 'whispir-font-faces';

const FONT_FACES = css`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400&display=swap');
  @import url('https://fonts.googleapis.com/icon?family=Material+Icons');

  @font-face {
    font-family: 'Capisce-Display';
    font-style: normal;
    font-weight: 400;
    src: url('https://www.whispir.com/fonts/capisce-display.woff2')
      format('woff2');
  }

  @font-face {
    font-family: 'Capisce-Text';
    font-style: normal;
    font-weight: 400;
    src: url('https://www.whispir.com/fonts/capisce-text.woff2') format('woff2');
  }

  /* Configuring the top-level DOM to remove padding and margins */
  html,
  body {
    padding: 0;
    height: 100%;
    margin: 0;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: grayscale;
    overscroll-behavior: none;
  }
`.styles;

// Taken from: https://gist.github.com/sbatson5/347a896e81e25afebd20fa5bea4c8125
export const createFonts = (): void => {
  if (document.getElementById(WHISPIR_FONTS_ID)) {
    return;
  }

  const fonts = document.createElement('style');
  fonts.id = WHISPIR_FONTS_ID;
  fonts.appendChild(document.createTextNode(FONT_FACES));

  document.head.appendChild(fonts);
};
