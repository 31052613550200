import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import { unregister } from './registerServiceWorker';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { Hosts } from './App.types';
import { createFonts } from './Foundation/Fonts';
import { createAnalytics } from './integrations';

const CUSTOM_ELEMENT_NAME = 'wsp-microfrontend-container';

customElements.define(
  CUSTOM_ELEMENT_NAME,
  class extends HTMLElement {
    private appMountPoint: HTMLElement;
    private stylesMountPoint: HTMLElement;

    constructor() {
      super();
      createFonts();
      createAnalytics();
      this.appMountPoint = document.createElement('section');
      this.stylesMountPoint = document.createElement('section');
    }

    getHosts(): Hosts {
      return JSON.parse(this.getAttribute('hosts') || '{}');
    }

    getChildren(): Hosts {
      return JSON.parse(this.getAttribute('children') || '{}');
    }

    connectedCallback() {
      this.appendChild(this.appMountPoint);
      this.appendChild(this.stylesMountPoint);

      const emotionCache = createCache({
        key: CUSTOM_ELEMENT_NAME, // Namespacing styles to prevent css leakage
        container: this.stylesMountPoint,
      });

      ReactDOM.render(
        <React.StrictMode>
          <CacheProvider value={emotionCache}>
            <App hosts={this.getHosts()} children={this.getChildren()} />
          </CacheProvider>
        </React.StrictMode>,
        this.appMountPoint,
      );
    }

    disconnectedCallback() {
      ReactDOM.unmountComponentAtNode(this.appMountPoint);
    }
  },
);

unregister();
