import React from 'react';
import { Router, RouterProps } from 'react-router-dom';
import { History } from 'history';

type CustomHistoryRouterProps = Omit<RouterProps, 'location' | 'navigator'> & {
  history: History;
};

// Taken from: https://stackoverflow.com/a/70646548
export const CustomHistoryRouter = ({
  history,
  ...props
}: CustomHistoryRouterProps) => {
  const [location, setLocation] = React.useState(history.location);

  React.useLayoutEffect(() => history.listen(setLocation), [history]);

  return <Router {...props} location={location} navigator={history} />;
};
